import { render, staticRenderFns } from "./CUInficationOrderView.vue?vue&type=template&id=43847cc7&scoped=true"
import script from "./CUInficationOrderView.vue?vue&type=script&lang=js"
export * from "./CUInficationOrderView.vue?vue&type=script&lang=js"
import style0 from "./CUInficationOrderView.vue?vue&type=style&index=0&id=43847cc7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43847cc7",
  null
  
)

export default component.exports